import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  FaHome,            // Home icon
   // Community or friends icon
  FaLock,            // Proposals or locked feature icon
  FaDonate,          // Donations or contributions icon
  FaHandHolding,     // Voting or giving icon
  FaHistory,         // History icon
  FaUsers,           // Delegates icon
} from 'react-icons/fa';
import './BottomNavbar.css'; // Add some styles

const Tabs = () => {
  return (
    <div className="bottom-nav">
      <NavLink to="/proposals" activeClassName="active">
        <FaLock />
        <span>Proposals</span>
      </NavLink>
      <NavLink to="/delegate" activeClassName="active">
        <FaDonate />
        <span>Delegate</span>
      </NavLink>
      <NavLink to="/" activeClassName="active">
        <FaHandHolding />
        <span>Vote</span>
      </NavLink>
      <NavLink to="/history" activeClassName="active">
        <FaHistory />
        <span>History</span>
      </NavLink>
      
    </div>
  );
};

export default Tabs;
