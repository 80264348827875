import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'


import { ethers } from 'ethers'

import {
  upVote,
  downVote,
  finalizeProposal
} from '../store/interactions'

const DaoInfo = () => {
  const dispatch = useDispatch()


  const provider = useSelector(state => state.provider.connection)
  const account = useSelector(state => state.provider.account)
  const balance = useSelector(state => state.token.balance)
  const daoBalance = useSelector(state => state.token.daoBalance)
  const delegatedDAO = useSelector(state => state.delegatedDAO.contract)
  const delegatorBalance = useSelector(state => state.delegatedDAO.delegatorBalance)
  const delegateeVotesReceived = useSelector(state => state.delegatedDAO.delegateeVotesReceived)
  const votingPeriodHours = useSelector(state => state.delegatedDAO.votingPeriodHours)
  const quorum = useSelector(state => state.delegatedDAO.quorum)
 
  useEffect(() => {
  }, [])

  return (
    <>
      <div className='stat-div'>
       <div className='stat-grid'>
        <strong>Voting Period</strong> <br />  7 Days</div>
        <div className='stat-grid'>
        <strong>DAO Treasury</strong> <br /> {account ? `${parseInt(daoBalance).toLocaleString()} SYSFI` : 'connect wallet · '}
        </div>
        <div className='stat-grid'>
        <strong>Quorum</strong> <br />52%
       
        </div> <div className='stat-grid'>
        <strong>Your Voting Power</strong> <br /> {
          parseFloat(delegateeVotesReceived) > 0 ? (parseFloat(delegateeVotesReceived) + parseFloat(balance)).toLocaleString() :
          parseFloat(balance) > 0 ? parseFloat(balance).toLocaleString() :
          parseFloat(delegatorBalance) > 0 ? `${parseFloat(delegatorBalance).toLocaleString()} Delegated` :
          account ? '0' :
          'connect wallet'
        }
        </div>
      
      </div>
    </>
  )
}

export default DaoInfo
