import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FaQuestionCircle } from 'react-icons/fa'; // FontAwesome question mark icon

const InfoModal = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <FaQuestionCircle
        onClick={handleShow}
        style={{ cursor: 'pointer', fontSize: '24px', color: '#007bff', margin:'10px', marginRight:"10%", }} // Adjust styling as needed
      />
      
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="modal-dark"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Documentation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>About SysFi DAO</h4>
          <p>
            The SysFi DAO (Decentralized Autonomous Organization) empowers its members to participate in the governance of the SysFi ecosystem through voting on proposals. Each proposal represents an opportunity for the community to influence the development and management of the SysFi platform.
            <br />
            <br />
            <strong>Voting on Proposals:</strong>
            <br />
            Members can express their support or opposition to proposals by 'Upvoting' or 'Downvoting'. Each vote is weighted according to the number of SYSFI tokens a member holds. The voting power of each member directly influences the outcome of proposals.
            <br />
            <br />
            <strong>Voting Requirements:</strong>
            <br />
            1. DAO members must hold SYSFI tokens and have not delegated their votes to participate in voting.
            <br />
            2. Votes must be cast within the voting period, which is set at the time of proposal creation. This ensures timely decision-making and adherence to the proposed timeline.
            <br />
            <br />
            <strong>Finalization Requirements:</strong>
            <br />
            1. All DAO members, regardless of whether they have delegated their votes, are eligible to finalize proposals. This inclusivity ensures that every member has a role in concluding the decision-making process.
            <br />
            2. Proposals are considered Passed if they achieve the required quorum within the voting period. The quorum represents the minimum percentage of total voting power that must be reached for a proposal to be approved.
            <br />
            3. Proposals can only be finalized as Failed after the voting period has expired, even if the proposal does not meet the quorum or is heavily opposed. This rule maintains the integrity of the voting process and ensures that proposals are not unduly delayed.
            <br />
            <br />
            <strong>Voting Period:</strong> The standard voting period for proposals is 7 days. This timeframe allows members sufficient opportunity to review and cast their votes.
            <br />
            <strong>Quorum:</strong> To pass a proposal, it must achieve a quorum of 52% of the total SYSFI voting power. This threshold ensures that a significant portion of the community supports the proposal before it is enacted.
            <br />
            <br />
          </p>

          <p>
            Members have the ability to create new proposals, which can include a variety of initiatives such as funding allocations, protocol upgrades, or other changes to the SysFi ecosystem. Proposals can direct the distribution of SYSFI tokens from the DAO treasury to individuals or projects that provide valuable services to the DAO.
            <br />
            <br />
            <strong>Proposal Requirements:</strong>
            <br />
            1. The DAO treasury must have sufficient SYSFI tokens to cover the proposed amount. This ensures that proposals are financially viable and that the DAO can fulfill its commitments.
            <br />
            2. Proposals must be well-defined and include clear objectives and justifications to enable informed voting by DAO members.
            <br />
            <br />
          </p>

          <p>
            DAO members can delegate their voting power to other members, allowing those delegates to vote on their behalf. Delegation can be a strategic choice for members who prefer to entrust their voting rights to others with more expertise or interest in specific areas.
            <br />
            Delegated voting power can be undelegated at any time, allowing members to reclaim their voting rights whenever they choose.
            <br />
            <br />
            <strong>Delegation Requirements:</strong>
            <br />
            1. Members who have delegated their votes (Delegators) are not eligible to receive additional delegations. This rule prevents conflicts of interest and ensures that the delegation process remains transparent.
            <br />
            2. Members who have received delegated votes (Delegatees) are not permitted to delegate their accumulated votes to others. This restriction maintains the focus of delegation and avoids potential abuse.
            <br />
            <br />
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleClose}>Close</button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default InfoModal;


