import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Blockies from 'react-blockies';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ethers } from 'ethers';


const History = () => {
  const proposals = useSelector(state => state.delegatedDAO.proposals);

  useEffect(() => {}, []);

  // Status mapping function
  const mapStatus = (status) => {
    const statusCode = Number(status);
    switch (statusCode) {
      case 0:
        return 'Active';
      case 1:
        return 'Passed';
      case 2:
        return 'Failed';
      default:
        return 'Unknown';
    }
  };

  return (
    <>
      <h1 className='text-center'>Proposal History</h1>
      <p className='text-center'>
        Below are all proposals that have been finalized by the DAO.
      </p>

      <hr />

      <div className='history-container'>
        {proposals.filter(proposal => Number(proposal.status) === 1 || Number(proposal.status) === 2).map((proposal, index) => (
          <div key={index} className='history-card'>
            <div className='card-header'>
              <Blockies
                seed={proposal.recipient.toString()}
                size={10}
                scale={3}
                color='#e6e6e6'
                bgColor='#000000'
                spotColor='#ffffff'
                className='identicon'
              />
              <OverlayTrigger
                overlay={
                  <Tooltip id={`tooltip-${index}`}>
                    {proposal.recipient.toString()}
                  </Tooltip>
                }
              >
                <span className='recipient'>
                  {proposal.recipient.toString().slice(0, 6) + '...' + proposal.recipient.toString().slice(-4)}
                </span>
              </OverlayTrigger>
            </div>
            <div className='card-body'>
              <h5 className='card-title'>{proposal.title.toString()}</h5>
              <p className='card-description'>{proposal.description.toString()}</p>
              <p className='card-amount'>
                Amount: {parseInt(ethers.utils.formatUnits(proposal.amount, 18).split('.')[0]).toLocaleString()} CT
              </p>
              <p className='card-votes'>
                Votes: {parseInt(ethers.utils.formatEther(proposal.votes).split(".")[0]).toLocaleString()}
              </p>
              <p className='card-status'>
                Status: {mapStatus(proposal.status)}
              </p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default History;
