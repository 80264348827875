import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import Blockies from 'react-blockies';
import { ethers } from 'ethers';
import Alert from './Alert';
import { delegateVotes, undelegateVotes } from '../store/interactions';
import { Container } from 'react-bootstrap';

const Delegate = () => {
  const [delegate, setDelegate] = useState('');
  const [error, setError] = useState('');
  const [formKey, setFormKey] = useState(Date.now());
  const [showAlert, setShowAlert] = useState(false);

  const dispatch = useDispatch();

  const provider = useSelector(state => state.provider.connection);
  const account = useSelector(state => state.provider.account);
  const balance = useSelector(state => state.token.balance);
  const token = useSelector(state => state.token.contract);
  const delegatedDAO = useSelector(state => state.delegatedDAO.contract);
  const delegatorDelegatee = useSelector(state => state.delegatedDAO.delegatorDelegatee);
  const delegatorBalance = useSelector(state => state.delegatedDAO.delegatorBalance);
  const delegateeVotesReceived = useSelector(state => state.delegatedDAO.delegateeVotesReceived);

  const isDelegating = useSelector(state => state.delegatedDAO.delegating.isDelegating);
  const isDelegatingSuccess = useSelector(state => state.delegatedDAO.delegating.isSuccess);
  const isDelegatingTxnHash = useSelector(state => state.delegatedDAO.delegating.transactionHash);
  const isUndelegating = useSelector(state => state.delegatedDAO.undelegating.isUndelegating);
  const isUndelegatingSuccess = useSelector(state => state.delegatedDAO.undelegating.isSuccess);
  const isUndelegatingTxnHash = useSelector(state => state.delegatedDAO.undelegating.transactionHash);

  const delegateHandler = async (e) => {
    e.preventDefault();
    setShowAlert(false);

    if (!ethers.utils.isAddress(delegate)) {
      setError('Invalid Ethereum address');
      return;
    }

    setError('');

    const success = await delegateVotes(provider, token, balance, delegatedDAO, delegate, dispatch);

    if (success) {
      // Optionally update state or refetch data
      // For example, refetch data from the server
    }

    setShowAlert(true);
  };

  const undelegateHandler = async (e) => {
    e.preventDefault();
    setShowAlert(false);

    const success = await undelegateVotes(provider, delegatedDAO, dispatch);

    if (success) {
      // Optionally update state or refetch data
      // For example, refetch data from the server
    }

    setShowAlert(true);
  };

  useEffect(() => {
    if (!isDelegating && !error) {
      setDelegate('');
      setFormKey(Date.now());
    }
  }, [isDelegating, error]);

  return (
    <>
      <h1 className='text-center'>Delegate Votes</h1>
      {Number(balance) > 0 && Number(delegateeVotesReceived) === 0 && Number(delegatorBalance) === 0 ? (
        <Card style={{ maxWidth: '450px' }} className='dark'>
          <Form key={formKey} onSubmit={delegateHandler}>
            <Form.Group style={{ maxWidth: '450px', margin: '50px auto' }}>
              <Form.Control
                type='text'
                placeholder='Enter delegate address'
                className='my-2'
                isInvalid={!!error}
                onChange={(e) => setDelegate(e.target.value)}
              />
              <Form.Control.Feedback type='invalid'>{error}</Form.Control.Feedback>
              {isDelegating ? (
                <Spinner animation='border' style={{ display: 'block', margin: '0 auto' }} />
              ) : (
                <Button variant='primary' type='submit' style={{ width: '70%', margin: '10px auto', display:"block" }}>
                  Delegate Votes
                </Button>
              )}
            </Form.Group>
          </Form>
        </Card>
      ) : Number(delegatorBalance) > 0 ? (
        <>
          <Container className="mt-4">
            <Card className="text-center bg-dark">
              <Card.Body>
                <div className="d-flex flex-column align-items-center">
                  <Blockies
                    seed={delegatorDelegatee}
                    size={10}
                    scale={3}
                    color='#e6e6e6'
                    bgColor='#000000'
                    spotColor='#ffffff'
                    className="identicon mb-3"
                  />
                  <h3 className='ad'>{delegatorDelegatee}</h3>
                  <Card.Text>
                    Tokens Delegated <br /> {parseInt(delegatorBalance).toLocaleString()} SYSFI
                  </Card.Text>
                </div>
              </Card.Body>
            </Card>
          </Container>
          <Form onSubmit={undelegateHandler}>
            <Form.Group style={{ maxWidth: '450px', margin: '25px auto' }}>
              {isUndelegating ? (
                <Spinner animation='border' style={{ display: 'block', margin: '0 auto' }} />
              ) : (
                <Button variant='primary' type='submit' style={{ width: '100%' }}>
                  Undelegate Votes
                </Button>
              )}
            </Form.Group>
          </Form>
        </>
      ) : Number(delegateeVotesReceived) > 0 ? (
        <Card style={{ maxWidth: '450px' }} className='dark'>
          <p className='d-flex flex-column justify-content-center align-items-center text-center' style={{ height: '300px' }}>
            You have received delegated voting power.
            <br/>
            You are unable to delegate your votes.
            <br/>
            <br/>
            (i.e. no chained delegation)
            <br />
            <br />
            -
            <br />
            <br />
            <strong>Votes Received:</strong> {parseInt(delegateeVotesReceived).toLocaleString()} SYSFI
          </p>
        </Card>
      ) : (
        <h5 className='text-center'>You do not hold SYSFI Token</h5>
      )}

      {showAlert && (
        <Alert
          message={isDelegating ? `Delegated votes successfully. Transaction hash: ${isDelegatingTxnHash}` : `Undelegated votes successfully. Transaction hash: ${isUndelegatingTxnHash}`}
          variant={isDelegatingSuccess || isUndelegatingSuccess ? 'success' : 'danger'}
          onClose={() => setShowAlert(false)}
        />
      )}
    </>
  );
};

export default Delegate;
