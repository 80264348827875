import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Blockies from 'react-blockies';

import { ethers } from 'ethers';

import Alert from './Alert';

import {
  upVote,
  downVote,
  finalizeProposal
} from '../store/interactions';

const Vote = () => {
  const dispatch = useDispatch();

  const [showAlert, setShowAlert] = useState(false);
  const [loadingUpVoteId, setLoadingUpVoteId] = useState(null);
  const [loadingDownVoteId, setLoadingDownVoteId] = useState(null);
  const [loadingFinalizeId, setLoadingFinalizeId] = useState(null);

  const provider = useSelector(state => state.provider.connection);
  const account = useSelector(state => state.provider.account);
  const balance = useSelector(state => state.token.balance);
  const daoBalance = useSelector(state => state.token.daoBalance);
  const delegatedDAO = useSelector(state => state.delegatedDAO.contract);
  const delegatorBalance = useSelector(state => state.delegatedDAO.delegatorBalance);
  const delegateeVotesReceived = useSelector(state => state.delegatedDAO.delegateeVotesReceived);
  const votingPeriodHours = useSelector(state => state.delegatedDAO.votingPeriodHours);
  const quorum = useSelector(state => state.delegatedDAO.quorum);
  const proposals = useSelector(state => state.delegatedDAO.proposals);
  const userVotes = useSelector(state => state.delegatedDAO.userVotes);

  const isUpVoting = useSelector(state => state.delegatedDAO.upVoting.isUpVoting);
  const isUpVotingSuccess = useSelector(state => state.delegatedDAO.upVoting.isSuccess);
  const isUpVotingTxnHash = useSelector(state => state.delegatedDAO.upVoting.transactionHash);
  const isDownVoting = useSelector(state => state.delegatedDAO.downVoting.isDownVoting);
  const isDownVotingSuccess = useSelector(state => state.delegatedDAO.downVoting.isSuccess);
  const isDownVotingTxnHash = useSelector(state => state.delegatedDAO.downVoting.transactionHash);
  const isFinalizing = useSelector(state => state.delegatedDAO.finalizing.isFinalizing);
  const isFinalizingSuccess = useSelector(state => state.delegatedDAO.finalizing.isSuccess);
  const isFinalizingTxnHash = useSelector(state => state.delegatedDAO.finalizing.transactionHash);

  const upVoteHandler = async (e, id) => {
    e.preventDefault();
    setShowAlert(false);
    setLoadingUpVoteId(id);

    const success = await upVote(provider, delegatedDAO, id, dispatch);
    setLoadingUpVoteId(null);

    if (success) {
      window.location.reload();
    }

    setShowAlert(true);
  };

  const downVoteHandler = async (e, id) => {
    e.preventDefault();
    setShowAlert(false);
    setLoadingDownVoteId(id);

    const success = await downVote(provider, delegatedDAO, id, dispatch);
    setLoadingDownVoteId(null);

    if (success) {
      window.location.reload();
    }

    setShowAlert(true);
  };

  const finalizeHandler = async (e, id) => {
    e.preventDefault();
    setShowAlert(false);
    setLoadingFinalizeId(id);

    const success = await finalizeProposal(provider, delegatedDAO, id, dispatch);
    setLoadingFinalizeId(null);

    if (success) {
      window.location.reload();
    }

    setShowAlert(true);
  };

  const isExpired = proposal =>
    Date.now() > proposal.timestamp.add(ethers.BigNumber.from(votingPeriodHours * 3600)).toNumber() * 1000;

  return (
    <>
      <h1 className='text-center'>Live Proposals</h1>

      <div className="proposal-cards">
        {proposals.filter(proposal => Number(proposal.status) === 0).map((proposal, index) => {
          const isFinalized = proposal.status === 0 ? 0 : 1;
          const canVote = parseFloat(delegateeVotesReceived) > 0 || parseFloat(balance) > 0;
          const canFinalize = parseFloat(delegateeVotesReceived) > 0 || parseFloat(balance) > 0 || parseFloat(delegatorBalance) > 0;

          return (
            <Card key={index} className="proposal-card">
              <Card.Body>
                <Card.Title>Proposal #{proposal.id.toString()}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">{proposal.title.toString()}</Card.Subtitle>
                <Card.Text>{proposal.description.toString()}</Card.Text>
                <div className="proposal-info">
                  <div className="proposal-amount">
                    Amount: {parseInt(ethers.utils.formatUnits(proposal.amount, 18).split('.')[0]).toLocaleString()} CT
                  </div>
                  <div className="proposal-recipient">
                    Recipient:
                    <Blockies
                      seed={proposal.recipient.toString()}
                      size={10}
                      scale={3}
                      color='#e6e6e6'
                      bgColor='#000000'
                      spotColor='#ffffff'
                      className="identicon mx-2"
                    />
                    <OverlayTrigger
                      overlay={
                        <Tooltip id={`tooltip-${index}`}>
                          {proposal.recipient.toString()}
                        </Tooltip>
                      }
                    >
                      <span>
                        {proposal.recipient.toString().slice(0, 6) + '...' + proposal.recipient.toString().slice(-4)}
                      </span>
                    </OverlayTrigger>
                  </div>
                  <div className="proposal-votes">
                    Votes: {parseInt(ethers.utils.formatEther(proposal.votes).split(".")[0]).toLocaleString()}
                  </div>
                  <div className="proposal-status">
                    Status: {isExpired(proposal) && Number(proposal.status) === 0 ? 'Expired' : mapStatus(proposal.status)}
                  </div>
                </div>
                <div className="proposal-actions">
                  {!isFinalized && canVote && !userVotes[proposal.id] &&
                    Date.now() < proposal.timestamp.add(ethers.BigNumber.from(votingPeriodHours * 3600)).toNumber() * 1000 && (
                      loadingUpVoteId === proposal.id ? (
                        <Spinner animation='border' />
                      ) : (
                        <Button variant='primary' onClick={(e) => upVoteHandler(e, proposal.id)}>
                          👍
                        </Button>
                      )
                    )
                  }
                  {!isFinalized && canVote && !userVotes[proposal.id] &&
                    Date.now() < proposal.timestamp.add(ethers.BigNumber.from(votingPeriodHours * 3600)).toNumber() * 1000 && (
                      loadingDownVoteId === proposal.id ? (
                        <Spinner animation='border' />
                      ) : (
                        <Button variant='primary' onClick={(e) => downVoteHandler(e, proposal.id)}>
                          👎
                        </Button>
                      )
                    )
                  }
                  {!isFinalized && canFinalize &&
                    parseFloat(ethers.utils.formatUnits(proposal.votes, 18).toString()) >= quorum &&
                    parseFloat(ethers.utils.formatUnits(proposal.amount, 18).toString()) <= parseFloat(daoBalance.toString()) &&
                    Date.now() < proposal.timestamp.add(ethers.BigNumber.from(votingPeriodHours * 3600)).toNumber() * 1000 && (
                      loadingFinalizeId === proposal.id ? (
                        <Spinner animation='border' />
                      ) : (
                        <Button variant='primary' onClick={(e) => finalizeHandler(e, proposal.id)}>
                          ✅
                        </Button>
                      )
                    )
                  }
                  {!isFinalized && canFinalize &&
                    Date.now() >= proposal.timestamp.add(ethers.BigNumber.from(votingPeriodHours * 3600)).toNumber() * 1000 && (
                      loadingFinalizeId === proposal.id ? (
                        <Spinner animation='border' />
                      ) : (
                        <Button variant='primary' onClick={(e) => finalizeHandler(e, proposal.id)}>
                          ❌
                        </Button>
                      )
                    )
                  }
                </div>
                <Card.Footer className="text-muted">
                  Deadline: {new Date(proposal.timestamp.add(ethers.BigNumber.from(votingPeriodHours * 3600)).toNumber() * 1000).toLocaleString()}
                </Card.Footer>
              </Card.Body>
            </Card>
          );
        })}
      </div>

      {isUpVoting && (
        <Alert
          message={'Up Vote Pending...'}
          transactionHash={null}
          variant={'info'}
          setShowAlert={setShowAlert}
        />
      )}
      {isDownVoting && (
        <Alert
          message={'Down Vote Pending...'}
          transactionHash={null}
          variant={'info'}
          setShowAlert={setShowAlert}
        />
      )}
      {isFinalizing && (
        <Alert
          message={'Finalization Pending...'}
          transactionHash={null}
          variant={'info'}
          setShowAlert={setShowAlert}
        />
      )}
      {isUpVotingSuccess && (
        <Alert
          message={'Up Vote Successful!'}
          transactionHash={isUpVotingTxnHash}
          variant={'success'}
          setShowAlert={setShowAlert}
        />
      )}
      {isDownVotingSuccess && (
        <Alert
          message={'Down Vote Successful!'}
          transactionHash={isDownVotingTxnHash}
          variant={'success'}
          setShowAlert={setShowAlert}
        />
      )}
      {isFinalizingSuccess && (
        <Alert
          message={'Proposal Finalized!'}
          transactionHash={isFinalizingTxnHash}
          variant={'success'}
          setShowAlert={setShowAlert}
        />
      )}
      {showAlert && !isUpVoting && !isDownVoting && !isFinalizing && (
        <Alert
          message={'Something went wrong!'}
          transactionHash={null}
          variant={'danger'}
          setShowAlert={setShowAlert}
        />
      )}
    </>
  );
};

const mapStatus = status => {
  switch (status) {
    case 0:
      return 'Active';
    case 1:
      return 'Rejected';
    case 2:
      return 'Accepted';
    default:
      return 'Unknown';
  }
};

export default Vote;
