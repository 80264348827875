import React from 'react';
 // Import the CSS for styling

const AnnouncementBanner = () => {
  return (
    <div className="announcement-banner">
        <a href='https://ico.sysfi.network' target='_blank'>
      <p className="announcement-text">
        30% Discount SYSFI Presale Offer at @$0.0015!
      </p>
      </a>
    </div>
  );
};

export default AnnouncementBanner;
